:root {
  --primary: #e5e5e5;
  --container: #cfcfd1;
  --secondary: var(--container);
  --slogan: #a4a5a9;
  --icon: #a4a5a9;
  --background: #52545b;
}

.App {
  text-align: center;
}

.App-logo {
  height: 95px;
  pointer-events: none;
}

.App-header {
  background-color: var(--background);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--primary);
}

.App-link {
  color: var(--secondary);
}
